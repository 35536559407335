@charset "UTF-8";

@media (min-width: 1023px) {

    /* ------------------------------------------------------------------ */
    /* col's */
    /* ------------------------------------------------------------------ */

    .hidden-desktop {
        display: none !important;
    }

    .col.nomargin {
        margin-right: 0px;
        margin-left: 0px;
    }

    .col.full.nomargin {
        width: 901px;
    }

    .col.half.nomargin {
        width: 450px;
    }

    /* ------------------------------------------------------------------ */
    /* global */
    /* ------------------------------------------------------------------ */

    header .wrapper,
    .content {
        margin-left: auto;
        margin-right: auto;
        width: 901px;
    }

    h1 {
        font-size: 1.875rem;
        margin-bottom: 20px;
    }

    h1.col.full {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        width: 901px;
    }

    /* ------------------------------------------------------------------ */
    /* header + nav */
    /* ------------------------------------------------------------------ */

    header {
        background: none;
        border-bottom: none;
        border-top: 2px solid #FFF;
        box-shadow: none;
        height: 120px;
        margin-top: 0px;
        padding: 2px 0px;
        position: fixed;
    }

    header .nav-fix {
        display: block;
        height: 120px;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        will-change: transform;
        z-index: -1;
    }

    header.scrolled .nav-fix {
        background-color: rgba(242, 242, 242, 0.99);
        border-bottom: 1px solid #404040;
    }

    @media (hover: hover) {
        header.scrolled .nav-fix {
            border-bottom: 3px solid #404040;
            transform: scaleY(.36);
            transform-origin: top;
            transition: all 200ms ease-out;
        }

        header.scrolled:hover .nav-fix {
            border-bottom: 1px solid #404040;
            transform: scaleY(1);
        }

        header.scrolled nav .LVL2>a {
            opacity: 0;
            transition: opacity 100ms ease-out 0;
        }

        header.scrolled:hover nav .LVL2>a {
            opacity: 1;
            transition: opacity 200ms ease-out 200ms;
        }
    }

    .logo,
    .slicknav_menu {
        display: none;
    }

    nav ul#menu {
        display: block;
        margin-top: -4px;
    }

    nav ul#menu.show,
    nav ul#menu li.show {
        opacity: 1;
    }

    nav ul#menu a {
        color: #595959;
    }

    nav ul#menu a:hover {
        color: #000000;
        text-decoration: none;
    }

    /* ------------------------------------------------------------------ */
    /* Startseite inverted */
    /* ------------------------------------------------------------------ */

    .startseite {
        padding-top: 0;
    }

    .startseite header {
        border-top: 2px solid rgba(32, 32, 32, .9);
        color: #eee;
    }

    .startseite nav ul#menu a {
        color: #eee;
    }

    .startseite nav ul#menu a:hover {
        color: #FFFFFF;
    }

    .startseite nav ul#menu>li {
        border-top: 2px solid rgba(32, 32, 32, .9);
    }

    .startseite nav ul#menu>li.active {
        border-top-color: #FFFFFF;
    }

    .startseite nav ul#menu li a.dot,
    .startseite nav ul#menu li.active a.dot,
    .startseite nav ul#menu li a.dot:active,
    .startseite nav ul#menu li a.dot:hover {
        background: url('../images/dot_white.png') no-repeat 58px 0px/12px 12px;
    }

    .startseite header.scrolled .nav-fix {
        background-color: rgba(32, 32, 32, .99);
        border-bottom: 1px solid #FFFFFF;
    }

    .keyvisual .backgroundtile {
        background-color: rgba(32, 32, 32, .9);
        display: block;
        height: 100%;
        position: absolute;
        right: 0;
        width: 33.4%;
    }

    .keyvisual .backgroundtile {
        left: 0;
        /* width of margin auto on one side of the 919px content */
        width: calc((100% - 919px)/2);
    }

    .keyvisual .introposition {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 919px;
    }

    .keyvisual .introtext {
        padding-left: 10px;
        padding-right: 145px;
        padding-top: 160px;
        width: 100%;
    }

    @media (hover: hover) {
        .startseite header.scrolled {
            background: none;
        }

        .startseite header.scrolled .nav-fix {
            border-bottom: 3px solid #FFFFFF;
        }

        .startseite header.scrolled:hover .nav-fix {
            border-bottom: 1px solid #FFFFFF;
        }
    }

    .startseite nav ul#menu li.active a.toplink,
    .startseite nav ul#menu li ul li.active a {
        color: #FFFFFF;
    }

    /* ------------------------------------------------------------------ */
    /* Ende Startseite inverted */
    /* ------------------------------------------------------------------ */

    nav ul#menu>li {
        float: left;
        padding: 5px 0;
        width: 15.8333333333%;
    }

    nav ul#menu>li {
        margin-left: 1%;
    }

    nav ul#menu>li:first-of-type {
        margin-left: 0;
    }

    nav ul#menu li a {
        display: block;
    }

    nav ul#menu li ul li {
        float: none;
        margin-left: 0;
        padding: 0;
    }

    nav ul#menu li ul li a {
        font-size: 0.9rem;
        font-weight: 400;
    }

    nav ul#menu>li {
        border-top: 2px solid #FFFFFF;
    }

    nav ul#menu>li.active {
        border-top-color: #000000;
    }

    nav ul#menu li.active a.toplink,
    nav ul#menu li ul li.active a {
        color: #000000;
    }

    nav ul#menu li a.toplink {
        margin-bottom: 5px;
    }

    nav ul#menu li.LVL2.active {
        border-top: none;
    }

    nav ul#menu li ul li.big a {
        text-transform: capitalize;
    }

    nav ul#menu li a.dot-red {
        background: url('../images/dot_red_0.png') no-repeat 44px 0px/12px 12px;
    }

    nav ul#menu li a.dot-red-1 {
        background: url('../images/dot_red_1.png') no-repeat 44px 0px/12px 12px;
    }

    nav ul#menu li a.dot-red-2 {
        background: url('../images/dot_red_2.png') no-repeat 44px 0px/12px 12px;
    }

    nav ul#menu li a.dot-red-3 {
        background: url('../images/dot_red_3.png') no-repeat 44px 0px/12px 12px;
    }

    nav ul#menu li a.dot-red-4 {
        background: url('../images/dot_red_4.png') no-repeat 44px 0px/12px 12px;
    }

    nav ul#menu li a.dot-red-5 {
        background: url('../images/dot_red_5.png') no-repeat 44px 0px/12px 12px;
    }

    nav ul#menu li a.dot-red-6 {
        background: url('../images/dot_red_6.png') no-repeat 44px 0px/12px 12px;
    }

    nav ul#menu li a.dot-red-uni {
        background: url('../images/dot_red_uni.png') no-repeat 44px 0px/12px 12px;
    }

    nav ul#menu li a.dot {
        background: url('../images/dot_hover.png') no-repeat 58px 0px/12px 12px;
    }

    nav ul#menu li.active a.dot,
    nav ul#menu li a.dot:active,
    nav ul#menu li a.dot:hover {
        background-image: url('../images/dot_black.png') no-repeat 58px 0px/12px 12px;
    }

    /* ------------------------------------------------------------------ */
    /* content */
    /* ------------------------------------------------------------------ */

    .introtext {
        font-size: 1.875rem;
    }

    .spaltentext {
        margin-bottom: 0;
    }

    #automotive h1,
    #navigation h1,
    #insights h1,
    #mobile h1,
    #application h1,
    #prototyping h1 {
        margin-top: 0;
    }

    .block {
        border-top: 1px solid #404040;
        padding-top: 5px;
    }

    .default {
        padding-top: 160px;
    }

    .default article {
        margin-top: 85px;
    }

    .default article:first-child,
    .intro {
        margin-top: 0px;
    }

    .keyvisual .bildslide {
        height: auto;
        position: absolute;
        width: 100%;
    }

    .keyvisual .bildslide img {
        display: block;
        min-height: 600px;
        max-height: 800px;
        height: 75vh;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }

    /* ------------------------------------------------------------------ */
    /* portfolio */
    /* ------------------------------------------------------------------ */

    .devices {
        height: 200px;
    }

    .devices .col.fourth {
        float: left;
        margin: 1.25rem 0 0 0;
        width: 225px;
    }

    .devices div {
        height: 150px;
        margin-top: 50px;
    }

    .devices img {
        bottom: 0;
        position: absolute;
    }

    .devices .phone {
        margin-left: 16px;
        z-index: -1;
    }

    .devices .ipad {
        margin-bottom: 5px;
        margin-left: 28px;
        z-index: -2;
    }

    .devices .laptop {
        display: block;
        margin-left: -91px;
        z-index: 2;
    }

    .devices .imac {
        margin-left: -35px;
        z-index: 1;
    }

    .devices .mini-control {
        margin-left: -50px;
        z-index: 3;
    }

    .devices .medium-control {
        margin-left: -17px;
        z-index: 2;
    }

    .devices .large-control {
        margin-left: 20px;
        z-index: 1;
    }

    .devices .instrument {
        margin-left: -85px;
        z-index: 3;
    }

    .devices .head {
        margin-left: 20px;
        z-index: 2;
    }

    .devices .unit {
        margin-bottom: 5px;
        margin-left: 108px;
        z-index: 1;
    }

    .project {
        margin-bottom: 0;
    }

    .project img {
        width: 100%;
    }

    .project .col.half {
        margin-top: 30px;
    }

    .project .col.half .img {
        width: 100%;
    }

    /* ------------------------------------------------------------------ */
    /* expertise */
    /* ------------------------------------------------------------------ */
    .flyingobjectsbg {
        position: relative;
        width: 100%;
    }

    .flyingobjectswrapper {
        margin-bottom: 3rem;
        min-height: 317px;
    }

    .flyingobjects {
        display: block;
        height: 100%;
    }

    .flyingobjects div {
        position: absolute;
    }

    .flyingobjects .background {
        display: block;
        left: 125px;
        margin-top: -64px;
    }

    .flyingobjects .background-tablet,
    .flyingobjects .bleistiftleft-mobil,
    .flyingobjects .radierer-tablet,
    .flyingobjects .bleistiftright-tablet {
        display: none;
    }

    .flyingobjects .edding {
        left: -95px;
        margin-top: 98px;
    }

    .flyingobjects .bleistiftleft {
        display: block;
        left: -95px;
        margin-top: 166px;
    }

    .flyingobjects .edding_deckel {
        left: 178px;
        margin-top: 98px;
    }

    .flyingobjects .marker {
        left: -95px;
        margin-top: 222px;
    }

    .flyingobjects .marker_deckel {
        left: 107px;
        margin-top: 222px;
    }

    .flyingobjects .radierer {
        display: block;
        left: 840px;
        margin-top: 125px;
    }

    .flyingobjects .bleistiftright {
        display: block;
        left: 1025px;
        margin-top: 145px;
    }

    .prototypingwrapper {
        background: url('../images/expertise/prototyping_base.png') no-repeat 204px 4px;
        min-height: 208px;
        position: relative;
        width: 100%;
    }

    .prototypingwrapper div {
        display: block;
        position: absolute;
    }

    .prototypingwrapper .circuit {
        left: -23px;
        margin-top: 45px;
    }

    .prototypingwrapper .components {
        left: 315px;
        margin-top: 39px;
    }

    .prototypingwrapper .code {
        left: 596px;
        margin-top: 78px;
    }

    .prototypingwrapper .push {
        left: 613px;
        margin-top: 27px;
    }

    .prototypingwrapper .ledwhite {
        left: 750px;
        margin-top: 5px;
    }

    .prototypingwrapper .con {
        left: 715px;
        margin-top: 63px;
    }

    .prototypingwrapper .pres {
        left: 885px;
        margin-top: 115px;
    }

    .prototypingwrapper .led {
        left: 915px;
        margin-top: 15px;
    }

    .navisprite {
        background: url('../images/expertise/navigation_sprite_up.png') no-repeat 0px 0px/auto;
        height: 300px;
        width: 100%;
    }

    #panorama-sprite {
        background: url('../images/startseite/ueber/panorama.png') -100px 0px no-repeat;
    }

    #muenster-sprite {
        background: url('../images/startseite/ueber/muenster.png') 0px 0px no-repeat;
        width: 209px;
    }

    /* ------------------------------------------------------------------ */
    /* kontakt */
    /* ------------------------------------------------------------------ */

    .openstreetmap {
        margin-bottom: 0;
    }

    /* ------------------------------------------------------------------ */
    /* jobs */
    /* ------------------------------------------------------------------ */

    .block.col.full.job {
        margin: 0;
        width: 901px;
    }

    .job .abbreviation {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
        padding: 0.9375rem 0.9375rem 0.1875rem 0.9375rem;
        width: 901px;
    }

    .job .abbreviation .big {
        font-size: 1150%;
        line-height: 75%;
    }

    .job .abbreviation .medium {
        font-size: 500%;
        line-height: 1.7;
    }

    .job .abbreviation .title {
        /*bottom: 11px;*/
        margin-bottom: 0;
        right: 15px;
    }

    .job .button {
        margin-top: 0;
    }

    /* ------------------------------------------------------------------ */
    /* Themenseite */
    /* ------------------------------------------------------------------ */

    /* Themenseite - Navigation */

    .themenseite header {
        border-top: 2px solid rgba(32, 32, 32, .9);
        color: #eee;
    }

    .themenseite nav ul#menu a {
        color: #eee;
    }

    .themenseite nav ul#menu a:hover {
        color: #FFFFFF;
    }

    .themenseite nav ul#menu>li {
        border-top: 2px solid rgba(32, 32, 32, .9);
    }

    .themenseite nav ul#menu>li.active {
        border-top-color: #FFFFFF;
    }

    .themenseite nav ul#menu li a.dot,
    .themenseite nav ul#menu li.active a.dot,
    .themenseite nav ul#menu li a.dot:active,
    .themenseite nav ul#menu li a.dot:hover {
        background: url('../images/dot_white.png') no-repeat 58px 0px/12px 12px;
    }

    .themenseite header.scrolled .nav-fix {
        background-color: rgba(32, 32, 32, .99);
        border-bottom: 1px solid #FFFFFF;
    }

    @media (hover: hover) {
        .themenseite header.scrolled {
            background: none;
        }

        .themenseite header.scrolled .nav-fix {
            border-bottom: 3px solid #FFFFFF;
        }

        .themenseite header.scrolled:hover .nav-fix {
            border-bottom: 1px solid #FFFFFF;
        }
    }

    .themenseite nav ul#menu li.active a.toplink,
    .themenseite nav ul#menu li ul li.active a {
        color: #FFFFFF;
    }

    /* Themenseite - Taskline */

    .task__information {
        height: 30px;
    }

    /* ------------------------------------------------------------------ */
    /* footer */
    /* ------------------------------------------------------------------ */

    footer {
        margin: 80px auto 0;
        width: 901px;
    }

    footer .links a {
        float: right;
    }
}

@media (min-width: 1340px) {

    /* ------------------------------------------------------------------ */
    /* Zertifikate
    /* ------------------------------------------------------------------ */
    #tuev {
        position: absolute;
        left: 0;
        top: 10%;
        transform: translate(-127%,0);
    }

    #tisax {
        position: absolute;
        left: 0;
        top: 55%;
        transform: translate(-115%,7%);
    }
}