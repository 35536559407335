@charset "UTF-8";
/*
    Mobile Menu Core Style
*/

.slicknav_menu .slicknav_menutxt,
.slicknav_menu .slicknav_icon,
.slicknav_menu .slicknav_icon-bar,
.slicknav_btn .slicknav_icon-bar+.slicknav_icon-bar,
.slicknav_nav li.slicknav-hidden {
    display: none;
}

.slicknav_nav ul,
.slicknav_nav li,
.slicknav_nav .slicknav_row,
.slicknav_nav a {
    display: block
}

.slicknav_menu .slicknav_no-text {
    margin: 0
}

.slicknav_nav .slicknav_arrow {
    background: url(../images/nav_plus.png) no-repeat 0 0/26px 26px;
    display: inline;
    float: right;
    height: 26px;
    width: 26px;
}

.slicknav_nav .slicknav_open .slicknav_arrow {
    background-image: url(../images/nav_minus.png);
}

.slicknav_nav .slicknav_item {
    cursor: pointer;
}

.slicknav_nav .slicknav_item a,
.slicknav_nav .slicknav_parent-link a {
    display: inline
}

.slicknav_menu:before,
.slicknav_menu:after {
    content: " ";
    display: table;
}

.slicknav_nav,
.slicknav_menu:after {
    clear: both
}

.slicknav_menu {
    margin-top: 0.125rem;
}

.slicknav_menu .LVL2 {
    font-size: 1.375rem;
}

/* Button */

.slicknav_btn {
    background: url(../images/burger.png) no-repeat 0 0/22px 22px;
    cursor: pointer;
    display: block;
    height: 22px;
    float: right;
    margin-right: 0.9375rem;
    margin-bottom: 1.125rem;
    margin-top: 0.3125rem;
    position: relative;
    padding: 0;
    width: 22px;
}

.slicknav_btn.slicknav_open {
    background-image: url(../images/close.png);
}

/* Button Lines */

.slicknav_menu .slicknav_icon-bar {
    background-color: #f5f5f5;
}

.slicknav_nav {
    background-color: rgb(24, 24, 24);
    display: block;
    width: 100%;
}

.slicknav_nav a,
.slicknav_nav a:visited {
    color: rgb(200, 200, 200);
    text-transform: capitalize;
    text-decoration: none;
}

.slicknav_nav a:active,
.slicknav_nav a:focus,
.slicknav_nav a:hover,
.slicknav_nav .slicknav_row:active a,
.slicknav_nav .slicknav_row:hover a {
    color: rgb(255, 255, 255);
    text-decoration: none;
}

.slicknav_nav,
.slicknav_nav ul {
    list-style: none;
    overflow: hidden;
}

.slicknav_nav .slicknav_row,
.slicknav_nav>li a {
    padding: 0.625rem 0.9375rem 0.375rem 0.9375rem;
}

.slicknav_nav ul,
.slicknav_nav .slicknav_item a,
.slicknav_nav .slicknav_parent-link a {
    margin: 0;
    padding: 0;
}

.slicknav_nav .slicknav_txtnode {
    margin-left: 15px;
}

.slicknav_nav .slicknav_row,
.slicknav_nav li.LVL2 {
    margin: 0;
}

.slicknav_parent>ul>li>a {
    padding: 0.625rem 2.5rem;
}