@charset "UTF-8";

@media (min-width: 768px) {

    /* ------------------------------------------------------------------ */
    /* col's */
    /* ------------------------------------------------------------------ */
    .col.full {
        width: 100%;
        margin-left: 0;
    }

    .col.three-fourth {
        width: 74.6%;
    }

    .col.two-third {
        width: 66%;
    }

    .col.half {
        width: 49.2%;
    }

    .col.third {
        width: 32.26%;
    }

    .col.fourth {
        width: 23.8%;
    }

    .col.fifth {
        width: 18.6%;
    }

    .col.sixth {
        width: 15.3333333333%;
    }

    .col {
        margin-left: 1.6%;
    }

    .col:first-of-type {
        margin-left: 0;
    }

    .col.half.left {
        margin-left: 0;
    }

    .hidden-tablet {
        display: none !important;
    }

    .hidden-mobile {
        display: block !important;
    }

    /* ------------------------------------------------------------------ */
    /* startseite */
    /* ------------------------------------------------------------------ */



    .introtextwrapper {
        position: absolute;
        width: 100%;
        z-index: 5;
    }

    .keyvisual .introtext {
        width: 100%;
    }

    .startseite .introtext h1 {
        font-size: 1.85rem;
        padding-left: 0.125rem;
    }

    /* ------------------------------------------------------------------ */
    /* insights */
    /* ------------------------------------------------------------------ */

    #muenster-sprite {
        height: 465px;
    }

    .insightscontainer {
        margin-top: -1px;
        float: left;
        width: 50%;
    }

    .insightscontainer img {
        width: 99%;
    }

    .kunden img.col.fifth:nth-of-type(5n+1) {
        margin-left: 0;
    }

    /* ------------------------------------------------------------------ */
    /* portfolio */
    /* ------------------------------------------------------------------ */
    .devices {
        height: 200px;
        margin-top: 1.25rem;
    }

    .devices .col.fourth {
        float: left;
        margin: 1.25rem 0 0 0;
        width: 25%;
    }

    .devices div {
        height: 150px;
        margin-top: 1.25rem;
    }

    .devices img {
        bottom: 0;
        position: absolute;
    }

    .devices .phone {
        margin-left: 16px;
        z-index: -1;
    }

    .devices .ipad {
        margin-bottom: 5px;
        margin-left: 28px;
        z-index: -2;
    }

    .devices .laptop {
        display: block;
        margin-left: -91px;
        z-index: 2;
    }

    .devices .imac {
        margin-left: -35px;
        z-index: 1;
    }

    .devices .mini-control {
        margin-left: -50px;
        z-index: 3;
    }

    .devices .medium-control {
        margin-left: -17px;
        z-index: 2;
    }

    .devices .large-control {
        margin-left: 20px;
        z-index: 1;
    }

    .devices .instrument {
        margin-left: -85px;
        z-index: 3;
    }

    .devices .head {
        margin-left: 20px;
        z-index: 2;
    }

    .devices .unit {
        margin-bottom: 5px;
        margin-left: 108px;
        z-index: 1;
    }

    .project {
        margin-bottom: 1.25rem;
    }

    .project .description strong {
        display: block;
    }

    /* ------------------------------------------------------------------ */
    /* expertise */
    /* ------------------------------------------------------------------ */

    .flyingobjectsbg {
        position: relative;
        width: 100%;
    }

    .flyingobjectswrapper {
        min-height: 272px;
    }

    .flyingobjects {
        display: block;
        height: 100%;
    }

    .flyingobjects div {
        position: absolute;
    }

    .flyingobjects .background,
    .flyingobjects .bleistiftleft,
    .flyingobjects .radierer,
    .flyingobjects .bleistiftright {
        display: none;
    }

    .flyingobjects .background-tablet {
        display: block;
        left: 40px;
        margin-top: -106px;
        z-index: -1;
    }

    .flyingobjects .edding {
        left: -158px;
        margin-top: 40px;
    }

    .flyingobjects .bleistiftleft-mobil {
        left: -140px;
        margin-top: 96px;
    }

    .flyingobjects .edding_deckel {
        left: 115px;
        margin-top: 40px;
    }

    .flyingobjects .marker {
        left: -156px;
        margin-top: 142px;
    }

    .flyingobjects .marker_deckel {
        left: 45px;
        margin-top: 142px;
    }

    .flyingobjects .radierer-tablet {
        display: block;
        left: 530px;
        margin-top: 80px;
    }

    .flyingobjects .bleistiftright-tablet {
        display: block;
        left: 660px;
        margin-top: 50px;
        overflow: hidden;
    }

    .prototypingwrapper {
        background: url('../images/expertise/prototyping_base.png') no-repeat 204px -16px;
        min-height: 198px;
        position: relative;
        width: 100%;
    }

    .prototypingwrapper div {
        display: block;
        position: absolute;
    }

    .prototypingwrapper .circuit {
        left: -23px;
        margin-top: 25px;
    }

    .prototypingwrapper .components {
        left: 315px;
        margin-top: 19px;
    }

    .prototypingwrapper .code {
        left: 596px;
        margin-top: 68px;
    }

    .prototypingwrapper .push {
        left: 613px;
        margin-top: 17px;
    }

    .prototypingwrapper .ledwhite {
        left: 750px;
        margin-top: -5px;
    }

    .prototypingwrapper .con {
        left: 715px;
        margin-top: 53px;
    }

    .prototypingwrapper .pres {
        left: 885px;
        margin-top: 165px;
    }

    .prototypingwrapper .led {
        left: 915px;
        margin-top: 5px;
    }

    /* ------------------------------------------------------------------ */
    /* kontakt */
    /* ------------------------------------------------------------------ */

    .kontakt .button.vcard {
        margin-top: 0;
    }

    .openstreetmap {
        margin-bottom: 2.5rem;
        position: relative;
        z-index: 0;
    }

    /* ------------------------------------------------------------------ */
    /* jobs */
    /* ------------------------------------------------------------------ */
    .job .abbreviation {
        padding-left: .938rem;
        position: relative;
        width: 96%;
    }

    .job .abbreviation .big {
        font-size: 1000%;
        text-align: left;
        width: auto;
    }

    .job .abbreviation .medium {
        font-size: 500%;
        text-align: left;
        width: auto;
    }

    .job .abbreviation .title {
        bottom: 0;
        margin-bottom: 0rem;
        margin-top: 0;
        position: absolute;
        right: 0;
        width: auto;
    }

    .job .downloadbutton {
        margin-top: 0.8125rem;
    }

    /* ------------------------------------------------------------------ */
    /* Themenseite */
    /* ------------------------------------------------------------------ */

    /* Themenseite - Taskline */

    .task__information {
        display: block;
        justify-content: unset;
        align-items: unset;
        height: 80px;
    }

    .task__information div {
        transform: unset;
        white-space: normal;
    }

    /* ------------------------------------------------------------------ */
    /* footer */
    /* ------------------------------------------------------------------ */

    footer {
        margin: 80px .9375rem 0;
    }

    footer .links a {
        float: left;
        margin-left: 6.8ch;
    }
}

/* ------------------------------------------------------------------ */
/* Zertifikate */
/* ------------------------------------------------------------------ */

div.cert {
    margin-top: 20px;
    display: flex;
    justify-content: center;
}

@media (max-width: 1022px) {
    .content {
        padding: 0.9375rem;
    }
}