@charset "UTF-8";
/* ------------------------------------------------------------------ */

/* basics */

/* ------------------------------------------------------------------ */

*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

* {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    text-rendering: optimizeLegibility;
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    background-color: #F2F2F2;
    color: #404040;
    font: normal 100%/1.3 FrutigerNextW01, "Helvetica Neue", Arial, Helvetica, sans-serif;
    height: 100%;
    overflow-x: hidden;
    padding-bottom: 2.5rem;
    width: 100%;
}

.container {
    width: 100%;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

a {
    border: none;
    color: #404040;
    cursor: pointer;
    text-decoration: none;
}

a:hover,
a:focus {
    color: #000000;
    text-decoration: underline;
}

a:active,
a:visited {
    color: #333;
    text-decoration: none;
}

a.button {
    background: #CCCCCC;
    border: none;
    border-radius: 20px;
    display: block;
    text-align: center;
    margin-top: 2.5rem;
    padding: 0.5rem 0;
    padding: 0.6125rem 0;
    width: 100%;
}

a.button:hover,
a.button:focus {
    background: #c2c2c2;
    text-decoration: none;
}

a.button:active {
    background: #b3b3b3;
    text-decoration: none;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

ul li {
    margin: 0;
    padding: 0;
}

img {
    border: none;
}

h1 {
    border-bottom: 2px solid #404040;
    font-size: 1.375rem;
    font-weight: normal;
    line-height: 1.3;
    margin: 0;
    margin-bottom: 1.25rem;
}

article+article,
section.content+section.content {
    margin-top: 2.5rem;
}

strong {
    font-weight: 500;
}

.toplink {
    font-size: 1.375rem;
    font-weight: normal;
    line-height: 1.3;
    margin: 0;
    padding: 0;
}

.img-responsive {
    height: auto;
    width: 100%;
}

/* ------------------------------------------------------------------ */

/* row's and col's */

/* ------------------------------------------------------------------ */

.row {
    clear: both;
    width: 100%;
    position: relative;
}

.col {
    min-height: 1px;
    position: relative;
    width: 100%;
}

.col.three-fourth,
.col.two-third,
.col.one-third,
.col.half,
.col.third,
.col.fourth,
.col.fifth,
.col.sixth {
    float: left;
}

.hidden-mobile {
    display: none;
}

.blend-in {
    transition: opacity 0.5s ease-out;
    opacity: 0;
}

.blend-in.show {
    opacity: 1;
}

.animateme {
    transition: all 50ms;
}

.icon {
    height: 19px;
    width: 19px;
}

.button .icon {
    margin-right: 0.625rem;
    margin-bottom: -4px;
}

/* ------------------------------------------------------------------ */

/* Page: Insight */

/* ------------------------------------------------------------------ */

#panorama-sprite {
    background: url('../images/startseite/ueber/panorama.png') -40px 0px no-repeat;
    bottom: -30px;
    height: 292px;
    position: relative;
    width: 100%;
}

#muenster-sprite {
    background: url('../images/startseite/ueber/muenster.png') 90% 0px no-repeat;
    bottom: 30px;
    height: 544px;
    position: absolute;
    right: 0px;
    width: 100%;
    z-index: -100;
}

/* ------------------------------------------------------------------ */

/* Leaflet map */

/* ------------------------------------------------------------------ */

#map {
    height: 300px;
}

.map-overlay {
    background: rgba(255, 255, 255, 0.75);
    border: 1px solid rgba(191, 191, 191, 0.75);
    bottom: 15px;
    left: 15px;
    padding: 0.9375rem;
    position: absolute;
    z-index: 500;
}

.kontakt .map-overlay .button {
    margin-bottom: 0;
    margin-top: 0.9375rem;
}

.kontakt #vcard {
    margin-top: 0;
}