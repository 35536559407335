@charset "UTF-8";
/* ------------------------------------------------------------------ */

/* header + nav */

/* ------------------------------------------------------------------ */

header {
    background: rgb(24, 24, 24);
    height: 60px;
    margin-top: -60px;
    padding: 0.625rem 0;
    position: fixed;
    top: 0;
    transition: margin-top 0.5s ease-out;
    width: 100%;
    z-index: 10;
}

header.show {
    margin-top: 0px;
}

.logo {
    float: left;
    height: 29px;
    margin-left: 0.9375rem;
    margin-top: 0.125rem;
    width: 85px;
}

.logo a {
    background: url('../images/logo.png') no-repeat 0 0/85px 29px;
    height: 29px;
    display: block;
    width: 85px;
}

nav {
    margin: auto 0;
    width: 100%;
}

nav ul#menu,
.nav-fix {
    display: none;
}

#menu,
#menu li {
    transition: opacity 0.5s ease-out;
    opacity: 0;
}

/* ------------------------------------------------------------------ */

/* content */

/* ------------------------------------------------------------------ */

.block {
    margin-top: 2.5rem;
    padding-top: 0.6125rem;
}

.default {
    padding-top: 6.25rem;
}

.introtext {
    background: none;
    font-size: 1.375rem;
    font-weight: normal;
    line-height: 138%;
}

.spaltentext {
    margin-bottom: 1.25rem;
}

.spaltentext:last-child {
    margin-bottom: 0;
}

#automotive h1,
#navigation h1,
#insights h1,
#mobile h1,
#application h1,
#prototyping h1 {
    margin-top: 2.5rem;
}

.button {
    padding: 0.5rem 0;
}

/* ------------------------------------------------------------------ */

/* startseite */

/* ------------------------------------------------------------------ */

.startseite {
    padding-top: 3.75rem;
}

.introtext.introtext-startseite {
    background-color: rgba(32, 32, 32, 0.9);
    color: #EEEEEE;
    font-size: 1.05rem;
    font-weight: 400;
    padding: 20px;
}

.introtext.introtext-startseite h1 {
    border-bottom: none;
    font-size: 1.375rem;
    font-weight: 300;
    line-height: 1.22;
    margin-bottom: 0.9375rem;
}

.startseite .block {
    border-top: 1px solid #555555;
}

.startseite .backgroundtile {
    display: none;
}

.keyvisual .bildslide {
    height: 0px;
    position: relative;
    width: 100%;
}

.keyvisual .bildslide img {
    width: 100%;
}

.startseite .spaltentext,
.startseite .spaltentext p:last-child {
    margin-bottom: 0;
}

.startseite p {
    margin-bottom: 0.9375rem;
}

/* ------------------------------------------------------------------ */

/* Über */

/* ------------------------------------------------------------------ */

#panorama-sprite {
    background: url('../images/startseite/ueber/panorama.png') -390px 0px no-repeat;
    margin-bottom: 2.5rem;
}

#muenster-sprite {
    background: url('../images/startseite/ueber/muenster.png') 105% 0px no-repeat;
}

.insightscontainer {
    float: none;
    margin-top: -0.1125rem;
    position: relative;
    width: 100%;
}

.insightscontainer .bildslide:first-child {
    position: absolute;
}

.insightscontainer img {
    width: 100%;
}

@media (max-width: 767px) {
    .kunden img.col.fifth {
        width: 33.3%;
    }
}


/* ------------------------------------------------------------------ */

/* Expertise */

/* ------------------------------------------------------------------ */

.flyingobjectsbg {
    position: relative;
    width: 100%;
}

.flyingobjectswrapper {
    min-height: 272px;
}

.flyingobjects {
    display: block;
    height: 100%;
}

.flyingobjects div {
    position: absolute;
}

.flyingobjects .background {
    left: -107px;
    margin-top: -106px;
    z-index: -1;
}

.flyingobjects .background-tablet,
.flyingobjects .bleistiftleft,
.flyingobjects .radierer,
.flyingobjects .bleistiftright-tablet {
    display: none;
}

.flyingobjects .edding {
    left: -230px;
    margin-top: 40px;
}

.flyingobjects .bleistiftleft-mobil {
    left: -153px;
    margin-top: 96px;
}

.flyingobjects .edding_deckel {
    left: 43px;
    margin-top: 40px;
}

.flyingobjects .marker {
    left: -183px;
    margin-top: 142px;
}

.flyingobjects .marker_deckel {
    left: 18px;
    margin-top: 142px;
}

.flyingobjects .radierer-tablet {
    display: block;
    left: 843px;
    margin-top: 125px;
}

.flyingobjects .bleistiftright {
    display: block;
    left: 1030px;
    margin-top: 145px;
}

.prototypingwrapper {
    background: url('../images/expertise/prototyping_circuit_complete.png') no-repeat center -40px;
    min-height: 170px;
    position: relative;
    width: 100%;
}

.prototypingwrapper div {
    display: none;
}

.navisprite {
    background: url('../images/expertise/navigation_sprite_up.png') no-repeat 0% 0%/cover;
    height: 120px;
    width: 100%;
}

/* ------------------------------------------------------------------ */

/* portfolio */

/* ------------------------------------------------------------------ */

.devices {
    height: 200px;
    margin-top: 1.25rem;
}

.devices .col.fourth {
    float: left;
    margin: 1.25rem 0 0 0;
    width: 25%;
}

.devices div {
    height: 150px;
    margin-top: 1.25rem;
}

.devices img {
    bottom: 0;
    position: absolute;
}

.devices .phone {
    margin-left: 16px;
    z-index: -1;
}

.devices .ipad {
    margin-bottom: 5px;
    margin-left: 28px;
    z-index: -2;
}

.devices .laptop {
    display: none;
    margin-left: -91px;
    z-index: 2;
}

.devices .imac {
    margin-left: -35px;
    z-index: 1;
}

.devices .mini-control {
    margin-left: -50px;
    z-index: 3;
}

.devices .medium-control {
    margin-left: -17px;
    z-index: 2;
}

.devices .large-control {
    margin-left: 20px;
    z-index: 1;
}

.devices .instrument {
    margin-left: -25px;
    z-index: 3;
}

.devices .head {
    margin-left: 20px;
    z-index: 2;
}

.devices .unit {
    margin-bottom: 5px;
    margin-left: 108px;
    z-index: 1;
}

.project {
    margin-bottom: 1.25rem;
}

.project:last-child,
.project .col:last-child {
    margin-bottom: 0;
}

.project .col {
    margin-top: 1.25rem;
}

.project .description strong {
    display: block;
}

/* ------------------------------------------------------------------ */

/* kontakt*/

/* ------------------------------------------------------------------ */

.kontakt .button {
    margin-bottom: 2.5rem;
}

.kontakt button#routenplaner {
    margin-top: 0;
}

/* ------------------------------------------------------------------ */

/* jobs*/

/* ------------------------------------------------------------------ */

.joblist,
.pgp_employee_list {
    margin-top: 2.5rem;
}

.joblist .block.col.full.job:last-child {
    border-bottom: 2px dotted #404040;
}

.block.col.full.job {
    margin-top: 0;
}

.block.job,
.pgp_block {
    border-top: 2px dotted #404040;
    padding-bottom: 1.25rem;
    padding-top: 1.25rem;
}

.block.job .disabled {
    opacity: 0.5;
}

.block.job h2,
.pgp_block h2 {
    background: none;
    font-size: 1.375rem;
    font-weight: normal;
    line-height: 1.3;
    margin-bottom: 0.3125rem;
    margin-top: 0.3125rem;
}

/* .block.job h2 .speculative_application {
    font-weight: 500;
} */

.block.job h2 .title {
    display: block;
}

.block.job .description {
    margin-bottom: 0.6rem;
}

.block.job .button,
.block.cert .button,
.pgp_block .button {
    margin-top: 1.25rem;
}

.job .button {
    margin-top: 0.9375rem;
}

.job .abbreviation {
    border-top: 2px dotted #404040;
    border-bottom: 2px dotted #404040;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    padding: 0.9375rem 0;
}

.job .abbreviation .big {
    font-size: 750%;
    font-weight: 300;
    line-height: 75%;
    text-align: left;
    width: 100%;
}

.job .abbreviation .medium {
    font-size: 300%;
    font-weight: 300;
    text-align: left;
    width: 100%;
}

.job .abbreviation .title {
    margin-top: 1.25rem;
    padding: 0;
    text-align: left;
    width: 100%;
}

.job .tasks,
.job .profil,
.job .offer {
    margin-bottom: 0.9375rem;
}

.job .profil .title,
.job .tasks .title,
.job .offer .title,
.job .interest .title {
    font-size: 1.375rem;
    font-weight: normal;
    margin-bottom: 0.6125rem;
}

.job .profil .title,
.job .offer .title,
.job .interest .title {
    border-top: 2px dotted #404040;
    padding-top: 1.25rem;
}

.job .interest .title {
    padding-left: 0;
}

.job .tasks ul,
.job .profil ul,
.job .offer ul {
    list-style: disc;
    margin-left: 0.9375rem;
}

.job .list ul li {
    margin-bottom: 0.3125rem;
}

/* ------------------------------------------------------------------ */
/* Zertifikate
/* ------------------------------------------------------------------ */

.block.cert {
    border-top: 2px dotted #404040;
}

/* ------------------------------------------------------------------ */
/* Themenseite */
/* ------------------------------------------------------------------ */

.themenseite {
    background: var(--themenseite__bg-image) no-repeat top center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: #fff;
}

.themenseite h1 {
    border-color: #eee;
}

/* Taskline*/

.TASKLINE {
    position: relative;
    margin-bottom: 50px;
    width: 100%;
    min-height: 100px;
}

.task__graphs {
    position: relative;
    min-height: 100px;
    border-bottom: 2px solid #FFF;
}

.task-information__wrapper {
    display: flex;
    width: 100%;
}

.task__information {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    width: 100%;
    height: 150px;
    padding: 6px 10px 0px 10px;
    min-width: 0;
}

.task__information div {
    transform: rotate(-90deg) translateX(-50%);
    white-space: nowrap;
}

.task__information::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 6px;
    border-left: 1px solid #FFF;
}

.BAR {
    position: absolute;
    left: 0px;
    bottom: 2px;
}

.themenseite footer {
    border-top-color: #eee;
}

/* Taskline Animation */

.show .ANIMATION1{
    animation-name: ANIMATION1;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;

    -webkit-animation-name: ANIMATION1;
    -webkit-animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
}

@keyframes ANIMATION1{
    0%  {margin-top: -30px; height:0px;width:25px;background-color: rgba(255,255,255,1.0);}
    50%  {background-color: rgba(255,255,255,0.5);}
}

@-webkit-keyframes ANIMATION1{
    0%  {margin-top: -30px; height:0px;width:25px;background-color: rgba(255,255,255,1.0);}
    50%  {background-color: rgba(255,255,255,0.5);}
}

.show .ANIMATION2{
    animation-name: ANIMATION2;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;

    -webkit-animation-name: ANIMATION2;
    -webkit-animation-duration: 1.5s;
    -webkit-animation-timing-function: ease-in-out;
}

@-webkit-keyframes ANIMATION2{
    0%  {margin-top: -30px; height:0px;width:25px;background-color: rgba(255,255,255,1.0);}
    50%  {background-color: rgba(255,255,255,0.3);}
}

@keyframes ANIMATION2{
    0%  {margin-top: -30px; height:0px;width:25px;background-color: rgba(255,255,255,1.0);}
    50%  {background-color: rgba(255,255,255,0.3);}
}

.show .ANIMATION3{
    background-color: rgba(255,255,255,0.8);
    animation-name: ANIMATION3;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
}

@-webkit-keyframes ANIMATION3 {
    0%    {margin-top: -34px; height:0px;width:20px;background-color: rgba(255,255,255,1.0);}
    50%    {margin-top: -186px; height:150px;background-color: rgba(255,255,255,0.2);}
    100%  {background-color: rgba(255,255,255,0.8);}
}

.show .ANIMATION4{
    background-color: rgba(255,255,255,0.2);
    animation-name: ANIMATION4;
    animation-duration: 1.4s;
    animation-timing-function: ease-in-out;
}

@-webkit-keyframes ANIMATION4 {
    0%    {margin-top: -34px; height:0px;width:20px;background-color: rgba(255,255,255,1.0);}
    50%    {margin-top: -106px; height:70px;background-color: rgba(255,255,255,0.6);}
    100%  {background-color: rgba(255,255,255,0.2);}
}

.show .ANIMATION5{
    background-color: rgba(255,255,255,0.7);
    animation-name: ANIMATION5;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
}

@-webkit-keyframes ANIMATION5 {
    0%    {margin-top: -34px; height:0px;width:20px;background-color: rgba(255,255,255,1.0);}
    50%    {margin-top: -76px; height:40px;background-color: rgba(255,255,255,0.4);}
    100%  {background-color: rgba(255,255,255,0.7);}
}

.show .ANIMATION6{
    background-color: rgba(255,255,255,0.4);
    animation-name: ANIMATION6;
    animation-duration: 1.2s;
    animation-timing-function: ease-in-out;
}

@-webkit-keyframes ANIMATION6 {
    0%    {margin-top: -34px; height:0px;width:20px;background-color: rgba(255,255,255,1.0);}
    50%    {margin-top: -156px; height:120px;background-color: rgba(255,255,255,0.8);}
    100%  {background-color: rgba(255,255,255,0.4);}
}

/* ------------------------------------------------------------------ */

/* footer*/

/* ------------------------------------------------------------------ */

footer {
    border-top: 1px solid #404040;
    font-size: 0.9rem;
    margin: 2rem 0.9375rem 0;
    padding-top: 0.3125rem;
}

/* ------------------------------------------------------------------ */

/* impressum*/

/* ------------------------------------------------------------------ */

.impressum article {
    margin-bottom: 2.5rem;
}

.impressum article:last-child {
    margin-bottom: 0;
}

.impressum .title {
    font-size: 1.375rem;
    font-weight: normal;
    margin-bottom: 0.6125rem;
    margin-top: 1.25rem;
}

/* ------------------------------------------------------------------ */

/* PGP - pgp selectors also in job  css section*/

/* ------------------------------------------------------------------ */

.pgp_header {
    background-color: #000000;
    height: 80px;
}

.pgp_stellar_logo {
    margin-left: .9375rem;
    color: #FFF;
    font-size: 1.375rem;
    display: block;
    background: url(../images/logo.png) no-repeat 0 0 / 85px 29px;
    text-decoration: none;
    height: 29px;
    width: 85px
}

.pgp_site {
    margin-top: 50px;
}