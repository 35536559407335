@charset "UTF-8";
/* CSS Document */

/* ------------------------------------------------------------------ */

/* fonts */

/* ------------------------------------------------------------------ */

@font-face {
    font-family: 'FrutigerNextW01';
    src: url('../fonts/FrutigerNextW01-Regular.woff2') format('woff2'),
        url('../fonts/FrutigerNextW01-Regular.woff') format('woff'),
        url('../fonts/FrutigerNextW01-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FrutigerNextW01';
    src: url('../fonts/FrutigerNextW01-Medium.woff2') format('woff2'),
        url('../fonts/FrutigerNextW01-Medium.woff') format('woff'),
        url('../fonts/FrutigerNextW01-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

/* @font-face {
    font-family: 'FrutigerNextW01';
    src: url('../fonts/FrutigerNextW01-Heavy.woff2') format('woff2'),
        url('../fonts/FrutigerNextW01-Heavy.woff') format('woff'),
        url('../fonts/FrutigerNextW01-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
} */

@font-face {
    font-family: 'FrutigerNextW01';
    src: url('../fonts/FrutigerNextW01-Light.woff2') format('woff2'),
        url('../fonts/FrutigerNextW01-Light.woff') format('woff'),
        url('../fonts/FrutigerNextW01-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}